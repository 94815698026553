<template>
  <div class="add-order">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="form-container"
    >
	    <h1 style="text-align: center;line-height: 3rem">提交反馈</h1>
        <el-form-item class="search-item" label="标题">
            <el-input v-model="ruleForm.title" placeholder="用户名"></el-input>
        </el-form-item>
        <el-form-item class="search-item" label="正文">
            <el-input v-model="ruleForm.content" type="textarea" placeholder="密码" rows="15"></el-input>
        </el-form-item>
        
      <el-form-item>
          <el-button  @click="submitForm()" type="primary">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {getRsaCode} from "@/utils/utils";

export default {
  data() {
    return {
      ruleForm: {
				title:'',
        content:'',
      },
      rules: {},
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    submitForm() {
        this.$http.post('/client/suggest/addSuggest', this.ruleForm).then(res => {
            if (res.code === 0) {
                this.$message({
                    message: "成功",
                    type: "success",
                });
            }
        })
    },
    resetForm() {
      this.$refs['ruleForm'].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.add-order {
    margin-top: 20px;
}
.textarea{
    max-width: 400px;
    margin-bottom: 20px;
    margin-left:20px
}
.title {
    font-size: 13px;
    margin-left: 20px;
    .demo {
        padding: 10px;
        border: solid 1px #eee;
        width: 150px;
        margin-bottom: 10px;
    }
}

@media (max-width: 450px) {
    .textarea{
        max-width: 90%;
        margin: 0 10px 10px 10px;
    }
}

::v-deep .el-form-item__label {
    padding-right: 15px;
}
</style>
